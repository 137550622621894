import {Image, parseImage} from "./Images";
import {Color, parseColor} from "./Color";
import {mapOrNull} from "../functional/Functions";

export interface CatalogItemVariant {
    id: number
    sku: string
    qualifier: string | null
    color: Color | null
    price: number
    oldPrice: number | null
    inStock: boolean
    isAvailable: boolean
    images: Image[]
}

export function isDisplayable(variant: CatalogItemVariant): boolean {
    return variant.isAvailable && (variant.color !== null || variant.qualifier !== null)
}

export function parseCatalogItemVariant(json: any): CatalogItemVariant {
    return {
        id: json.id,
        sku: json.sku,
        qualifier: json.qualifier || null,
        color: mapOrNull(json.color, parseColor),
        price: (json.discountedPrice > 0 && json.discountedPrice) || json.price,
        oldPrice: (json.discountedPrice > 0) && json.price,
        inStock: json.stock > 0,
        isAvailable: json.isAvailable,
        images: (json.images as any[])?.map(parseImage) || [],
    }
}

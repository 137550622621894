import "./ProductVariantSelector.css"
import React from "react";
import {CatalogItemVariant, isDisplayable} from "../../../data/CatalogItemVariant";

export function ProductVariantSelector(props: {
    variants: CatalogItemVariant[],
    selectedVariant: CatalogItemVariant,
    setVariant: (variant: CatalogItemVariant) => void,
}) {
    const displayableVariants = props.variants.filter(isDisplayable)
    const VariantItem = (variant: CatalogItemVariant, index: number) => {
        let itemStyle = "ProductVariantSelectorItem " + (variant.id === props.selectedVariant.id && "Active") || ""
        return <li key={index} className={itemStyle}
                   onClick={() => props.setVariant(variant)}
                   title={`Артикул ${variant.sku}`}>
            <div className="ProductVariantSelectorItemColor"
                 style={{backgroundColor: (variant.inStock && variant.color?.colorHex) || "transparent"}}/>
            {variant.qualifier && <div className="ProductVariantSelectorItemTitle">
                {variant.qualifier}
            </div>}
        </li>
    }
    return <>
        {displayableVariants.length > 0 && <ul className="ProductVariantSelector">
            {displayableVariants.map(VariantItem)}
        </ul>}
    </>
}

import './CatalogCard.css';
import {CatalogItem, catalogItemLink} from "../../../data/CatalogItems";
import {useCartItemControl} from "../../../redux/CartSlice";
import {OutlinedButton} from "../../primitives/OutlinedButton";
import {CatalogSettings} from "../../../data/CatalogSettings";
import {Link} from "react-router-dom";
import {PriceView} from "../PriceView";
import {getCatalogItemImage} from "../../../data/Catalog";
import {usePreferredCurrency} from "../../../hooks/Currency";
import React, {useState} from "react";
import {ProductVariantSelector} from "../product/ProductVariantSelector";
import {isDisplayable} from "../../../data/CatalogItemVariant";

export function CatalogCard(item: CatalogItem & CatalogSettings) {
    const isInStock = item.variants.find((variant) => variant.inStock) !== undefined
    const [selectedVariant, setSelectedVariant] = useState(item.variants.find(isDisplayable) || item.variants[0])
    const [preferredCurrency] = usePreferredCurrency()
    const [addToCart, removeFromCart, isItemInCart] = useCartItemControl({...item, variantId: selectedVariant?.id})
    return <div className="CatalogCard">
        <Link to={catalogItemLink(item)}>
            <img src={getCatalogItemImage(item).previewUrl} alt={item.title}/>
        </Link>
        <div className="CatalogCardOverlay">
            <ProductVariantSelector
                variants={item.variants}
                selectedVariant={selectedVariant}
                setVariant={setSelectedVariant}
            />
            {isItemInCart && <OutlinedButton label="Удалить из корзины" onClick={removeFromCart}/>}
            {!isItemInCart && <OutlinedButton label="Добавить в корзину" onClick={addToCart}/>}
        </div>
        <div className="CatalogCardProperties">
            <div className="CatalogCardPropertiesLeft">
                {selectedVariant.sku}
                <h2>{item.title}</h2>
                <PriceView
                    catalogCategory={item.category}
                    catalogItemVariant={selectedVariant}
                    catalogSettings={item}
                    selectedCurrency={preferredCurrency || item.primaryCurrency}
                />
            </div>
            <div className="CatalogCardPropertiesRight">
                {isInStock && <p>В наличии</p>}
                {!isInStock && <p>Под заказ</p>}
            </div>
        </div>
    </div>
}
